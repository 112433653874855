<template>
  <div>
    <SectionWrapper
      v-if="!loadingGetFinance && !loadingAddress"
      title="Bank details"
      description="Payout happens every month to the provided information. Payment receipts will be send by email."
      :show-save-button="!!initialAddress"
      :is-valid-section="isValidSection"
      :loading="loadingSetFinance"
      @saveSection="setFinance">
      <div v-if="initialAddress" class="grid grid-cols-4 gap-3">
        <div class="col-span-4 md:col-span-4">
          <div>
            <label for="iban" class="block text-sm font-medium text-gray-700">{{ isDenmark ? "Reg. number" : "IBAN" }}</label>
            <div class="mt-1">
              <input
                id="iban"
                v-model="iban"
                type="text"
                name="Iban"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
            <span class="input-error">{{ errors.iban }}</span>
          </div>
        </div>
        <div class="col-span-4 md:col-span-4">
          <div>
            <label for="swift" class="block text-sm font-medium text-gray-700">{{ isDenmark ? "Account number" : "SWIFT" }}</label>
            <div class="mt-1">
              <input
                id="swift"
                v-model="swift"
                type="text"
                name="swift"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <span class="input-error">{{ errors.swift }}</span>
        </div>
        <div class="col-span-4 md:col-span-4">
          <div>
            <label for="vat" class="block text-sm font-medium text-gray-700">{{ isDenmark ? "CVR. nr." : "VAT no." }}</label>
            <div class="mt-1">
              <input
                id="vat"
                v-model="vat"
                type="text"
                name="vat"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <span class="input-error">{{ errors.vat }}</span>
        </div>
        <div v-if="!isDenmark" class="col-span-4 md:col-span-4">
          <div>
            <label for="org" class="block text-sm font-medium text-gray-700">Org. no.</label>
            <div class="mt-1">
              <input
                id="org"
                v-model="org"
                type="text"
                name="org"
                class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <span class="input-error">{{ errors.org }}</span>
        </div>        
      </div>
      <div v-else class="bg-red-50 border rounded-lg border-red-200 p-2">
        <span class="text-sm text-red-400">
          You have to provide you <router-link :to="{
                                                 name: 'address'
                                               }"
                                               class="hover:underline font-semibold">
            address
          </router-link> first!
        </span>
      </div>
    </SectionWrapper>
    <div v-else class="loading-state flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
      <LoadingSpinner outer-class="h-10 h-10 text-water" />
    </div>
    <SectionWrapper 
      :show-save-button="false"
      title="Your invoices">
      <div>
        <select v-model="year">
          <option v-for="year in years" :key="year.id">{{ year.name }}</option>
        </select>
      </div>
      <div>
        <table id="tblInvoices" class="mt-10 text-gray-700 table table-auto border divide-y divide-gray-200">
          <tbody>
            <tr v-for="(month, index) in pastMonths" :key="month">
              <td>{{month}} {{ year }} - #{{pastMonths.length-  index}}{{ year }}</td>
              <td class="right"><a :href="`https://backoffice.owayy.com/api/pdf/${year}/${pastMonths.length-  index}/${auth}`">Download PDF</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </SectionWrapper>
    <SectionWrapper  
      v-if="admin"
      :show-save-button="false"
      title="Edit invoices">
      <div>
        <div class="mt-1">
          <label for="year" class="block text-sm font-medium text-gray-700">Year:</label>
          <select v-model="selectedYear" class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md">
            <option v-for="year in years" :key="year.id">{{ year.name }}</option>
          </select>
        </div>
        <div class="mt-1">
          <label for="month" class="block text-sm font-medium text-gray-700">Month:</label>
          <select id="month" v-model="selectedMonth" class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" @change="handleMonthChange">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <label for="host" class="block text-sm font-medium text-gray-700">Host:</label>
        <select v-model="selectedUserId" class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" @change="handleMonthChange">
          <option value="">Select a user</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} ({{ user.email }})</option>
        </select>
        <div v-if="products.length" class="mt-1">
          <label for="host" class="block text-sm font-medium text-gray-700">Listing:</label>

          <select  v-model="selectedProductId" class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" @change="handleProductChange">
            <option value="">Select a product</option>
            <option
              v-for="product in products" 
              :key="product.product_id" :value="product.product_id">
              {{ product.product }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <label for="booking-select" class="block text-sm font-medium text-gray-700">Guest:</label>
        <select id="booking-select" v-model="selectedBooking" class="focus:ring-highlight-500 focus:border-highlight-500 block w-full sm:text-sm border-gray-300 rounded-md" @change="handleBookingChange">
          <option v-for="booking in bookingUsers" :key="booking.id" :value="booking">
            {{ booking.customer_name }}
            ({{ booking.id }})
          </option>
        </select>

        <table v-if="selectedBooking" class="mt-10 bg-gray-100 h-full border rounded-md mt-6 p-4">
          <thead>
            <tr>
              <th>Day</th>
              <th>Price per Day</th>
              <th>Addon Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(day, index) in bookingDays" :key="index">         
              <td>{{ day }}</td>
              <td>
                <input :key="index" v-model="prices[index]" :disabled="day.startsWith('*')" type="text" />
              </td>
              <td v-if="index === 0">
                <input v-model="selectedBooking.addon_price" :disabled="day.startsWith('*')" type="text" />
              </td>          
            </tr>
          </tbody>
        </table>
        <button class="mt-10 bg-highlight-500 text-white px-4 py-2 rounded-md" @click="saveInvoice">Save</button>
        <button class="mt-10 bg-highlight-500 text-white px-4 py-2 rounded-md" @click="downloadPdf">Download PDF</button>
      </div>
    </SectionWrapper>
    <div class="relative hidden">
      <NoFooter
        title="Payouts"
        description="Coming soon..">
        <div class="relative">
          <div class="h-28">
            <p>bla</p>
          </div>
          <div class="absolute top-0 inset-0">
            <div class="bg-gray-100 h-full border rounded-md mt-6 p-4">
              <p>Payouts<span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Soon </span></p>
            </div>
          </div>
        </div>
      </NoFooter>
    </div>
  </div>
</template>

<script>
import { useQuery, useMutation } from "@vue/apollo-composable";
import { computed } from "vue";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import NoFooter from "@/components/UI/SectionWrappers/NoFooterWrapper";
import { GET_FINANCE, SET_FINANCE } from "@/graphql/finance";
import { GET_ADDRESS } from "@/graphql/address";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { notify } from "@kyvg/vue3-notification";
import Cookies from "js-cookie";
import axios from 'axios';
import { useStore } from "vuex";



export default {
  name: "Information",
  components: { NoFooter, SectionWrapper, LoadingSpinner },
  // url:'https://backoffice.owayy.com/api/',
  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
      bookings: [], 
      selectedBooking: null, 
      selectedUserId: '',
      selectedProductId:0,
      paymentPrices:{},
      users: [],
      prices: [],
      globalBookings:[],
      products: [],
      auth: Cookies.get('auth_session'),
      months: ["January", "February", "March", "April", "May", "June", "July",
               "August", "September", "October", "November", "December"],
      years: [
        { id: 2021, name: '2021' },
        { id: 2022, name: '2022' },
        { id: 2023, name: '2023' }
      ],
      year:'2023',
      url: process.env.VUE_APP_API_URL

    }     
  },
  methods:{
    downloadPdf(){
      fetch(`https://backoffice.owayy.com/api/pdf/user/${this.selectedUserId}/${this.selectedYear}/${this.selectedMonth}`, { 
        Authorization: `Bearer ${Cookies.get('auth_session')}`
      })
        .then(response => {
          if (response.ok) {
            response.blob().then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'invoice.pdf';
              a.click();
              window.URL.revokeObjectURL(url);
            });
          } else {
          }
        });
    },
    saveInvoice(){

      const items = this.prices.map((price, index) => ({ day: index, price }));
      this.paymentPrices[this.selectedBooking.id] = [...items]

      const data = {
        items,
        payment_id: this.selectedBooking.id,
        addons: this.selectedBooking.addon_price
      };
      
      axios.post(`${this.url}/reports`, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get('auth_session')}`
        }
      }).then(response => {
        notify({
          title: "Invoice was successfully saved",
          type: "success"
        });
        return response;
      });

    },
    handleProductChange(){
      this.selectedBooking = null;
    },
    handleBookingChange(){
      if(this.selectedBooking.invoices.length === 0)
        this.prices = Array(this.bookingDays.length).fill(this.pricePerDay);
      else if(this.paymentPrices[this.selectedBooking.id])
        this.prices = this.paymentPrices[this.selectedBooking.id].map(item => item.price);
      else
        this.prices = this.selectedBooking.invoices.map(invoice => invoice.price);
    },
    handleMonthChange(){
      this.selectedBooking = null;
      axios.get(`${this.url}/reports/${this.selectedUserId}/${this.selectedYear}/${this.selectedMonth}`,  {
        headers: {
          Authorization: `Bearer ${Cookies.get('auth_session')}`
        }
      })
        .then(response => {
          this.globalBookings = response.data;
          this.products = [];
          const map = new Map();
          for (const obj of response.data) {
            const key = obj.product + obj.product_id;

        

            if (!map.has(key)) {
              map.set(key, true);
              this.products.push(obj);
            }
          }});
    },
    fetchUsers() {
      axios.get(`${this.url}/user/list`,  {
        headers: {
          Authorization: `Bearer ${Cookies.get('auth_session')}`
        }
      })
        .then(response => {
          this.users = response.data;
        });
    },
    fetchProducts() {
      if (this.selectedUserId) {
        axios.get(`${this.url}/product/user/${this.selectedUserId}`,{
          headers: {
            Authorization: `Bearer ${Cookies.get('auth_session')}`
          }
        })
          .then(response => {
            this.products = response.data;
          });
      } else {
        this.products = [];
      }
    }
  },
  computed: {
    bookingUsers(){
      if (this.globalBookings) {
        const users = [];
        for (const booking of this.globalBookings) {
          if(booking.product_id === this.selectedProductId)
            users.push(booking);
        }
        return users.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
      }
      return [];
    },
    bookingDays() {
      if (this.selectedBooking) {
        const fromDate = new Date(this.selectedBooking.from_date);
        const toDate = new Date(this.selectedBooking.to_date);
        const days = [];
        for (let date = fromDate; date < toDate; date.setDate(date.getDate() + 1)) {
          if((date.getMonth()+1) >= this.selectedMonth)
            days.push(date.toISOString().split('T')[0]);
          else
            days.push(`*${date.toISOString().split('T')[0]}`);
        }
        return days;
      }
      return [];
    },
    pricePerDay() {
      if (this.selectedBooking) {
        const fromDate = new Date(this.selectedBooking.from_date);
        const toDate = new Date(this.selectedBooking.to_date);
        const totalDays = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));
        return this.selectedBooking.total_amount / totalDays;
      }
      return 0;
    },
    pastMonths() {
      if(this.year === '')
        return [];

      if(this.year === '2023'){
        const currentMonth = new Date().getMonth();
        return this.months.slice(0, currentMonth).slice().reverse();
      }
      
      return this.months.slice().reverse();
    }
  },
  mounted() {
    const store = useStore();
    this.admin = (store.state.auth.user.id === 10);

    if(this.admin)
      this.fetchUsers();
  },
  setup() {

    const { result: addressData, loading: loadingAddress } = useQuery(GET_ADDRESS, null, () => ({
      fetchPolicy:  'network-only'
    }));

    const initialAddress = computed(() => addressData.value?.getAddress ?? null);

    const isDenmark = computed(() => initialAddress.value.country_id === 7 ?? false );

    const schema = yup.object({
      iban: yup.string().required('This field is mandatory').nullable(),
      swift: yup.string().required('This field is mandatory').nullable()
    })

    const { values, errors, resetForm } = useForm({
      initialValues: {
        iban: null,
        swift: null,
        vat: null,
        org: null
      },
      validationSchema: schema
    })

    const { value: iban } = useField('iban');
    const { value: swift } = useField('swift');
    const { value: vat } = useField('vat');
    const { value: org } = useField('org');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const { loading: loadingGetFinance, onResult } = useQuery(GET_FINANCE, null, () => ({
      fetchPolicy:  'network-only'
    }));

    onResult(result => {
      const { data: { getFinance: {
        iban = null,
        swift = null,
        vat = null,
        org = null
      } } } = result;

      resetForm({
        touched: false,
        values: {
          iban,
          swift,
          vat,
          org
        }
      });
    })

    const { mutate: setFinance, loading: loadingSetFinance, onDone } = useMutation(SET_FINANCE, () => ({
      variables: {
        data: {
          iban: values.iban,
          swift: values.swift,
          vat: values.vat,
          org: values.org
        }
      }
    }))

    onDone((data) => {
      const { data: { setFinance: { iban, swift, vat, org } } } = data;

      notify({
        title: "Finance section was successfully saved",
        type: "success"
      });

      resetForm({
        touched: false,
        values: {
          iban,
          swift,
          vat,
          org
        }
      });
    })

    return {
      loadingGetFinance,
      setFinance,
      loadingSetFinance,
      loadingAddress,
      initialAddress,
      isDenmark,
      iban,
      swift,
      vat,
      org,
      errors,
      isValidSection
    }
  }
};
</script>

<style scoped>
table{
  width:100%;

}

tr{
  text-align:left;
  border: 1px solid gray;
}

td{
  padding: 5px;
  padding-left: 10px;
}

#tblInvoices{
  background: #F3F4F6;
}

#tblInvoices a{
  color: #C77DFF;
}

.right{
  text-align: right;
}
</style>
